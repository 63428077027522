<template>

  <RsvpMiniParty :club-config="clubConfig"
                 :nice-date="this.eventDate"
                 :mini-event=" this.event">
    <!--    <router-link :to="{path: this.$route.path + '/reservations?' + 'reserve=' + this.event.slug }">-->
    <div class="card card-animation-on-hover text-left"
         style="
          margin: 4px;
          padding: 4px;
          background-color: rgb(45, 44, 44);
          opacity: 0.9;
        "
    >
      <div

      >
        <img
          v-lazy="
            this.event.metadata.main_image.imgix_url +
            `?w=${this.imageSize}&h=${this.imageSize}&fit=clamp`
          "
          style="
            float: left;
            margin-right: 10px;
            z-index: 12;
            box-shadow: 0px 10px 15px 0px black;
            border-radius: 0;
          "
          v-bind:alt="this.event.title"
          :height="this.imageSize"
          :width="this.imageSize"
        />
        <div style="vertical-align: top; color: white; line-height: 1.1rem;">
          <span
            style="font-weight: 700 !important; font-size: 1rem"
            v-if="!withoutBrand"
          >{{ this.event.title.split('@')[0] }}</span
          >
          <span
            style="font-weight: 700 !important; font-size: 1rem"
            v-if="withoutBrand"
          >{{ this.event.title.split('@')[0].split('|')[0] }}</span
          >

          <p style="color: white !important">
            {{ niceDate(this.event) }}
          </p>
          <small
            v-if="this.withVenue"
            style="margin-top: -50px; color: silver; text-transform: uppercase"
          >{{ venue }}
          </small>
          <!--          <br v-if="event.metadata.content">-->
          <!--          <div v-html="event.metadata.content" v-if="event.metadata.content"></div>-->

        </div>
        <small style="font-size: 0.6rem;background-color: #0e0e0e;bottom: 0px; right: 0;position: absolute"
               class="pull-right"
               v-if="!past"
        >
          <i class="tim-icons icon-single-copy-04 " style="padding: 5px"></i>
          <i class="tim-icons icon-chat-33" style="padding: 5px"></i>
        </small>
      </div>
      <!--    </router-link>-->
    </div>

  </RsvpMiniParty>
</template>
<script>
import { venueDecoder } from '@/common/Decoder';
import RsvpMiniParty from '@/components/Reservations/RsvpMiniParty';
import { eventDateUtils } from '@/common/eventDateUtils';

export default {
  name: 'MiniPartyCard',
  components: { RsvpMiniParty },
  mixins: [eventDateUtils],
  props: ['event', 'past', 'withVenue', 'withoutBrand', 'large', 'clubConfig'],

  data: () => {
    return {};
  },
  computed: {
    venue: {
      get: function() {
        return venueDecoder(this.event.title).title || 'tulum';
      }
    },
    imageSize: {
      get: function() {
        return this.large ? '100' : '70';
      }
    },
    eventDate: {
      get: function() {
        return this.niceDate(this.event);
      }
    },
    config: {
      get: function() {
        return {
          event: this.event,
          niceDate: this.niceDate(this.event)
        };
      }
    }
  },
};
</script>
